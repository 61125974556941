import { PrismicSlice, SliceClass } from 'interfaces';

export class CountDownSlice extends SliceClass {
  constructor(
    sliceData: PrismicSlice,
    public countdownDate: string
  ) {
    super(sliceData);
  }
}
