import { NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, input, Input, signal } from '@angular/core';
import { CountdownComponent } from 'countdown';

@Component({
  selector: 'lib-promotion-banner',
  templateUrl: './promotion-banner.component.html',
  styleUrls: ['./promotion-banner.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgClass, CountdownComponent],
})
export class PromotionBannerComponent {
  @Input() text?: string;
  @Input() color = 'featured';
  @Input() position = 'normal';
  @Input() dynamicSize = true;
  @Input() margin = '';
  @Input() width = 'w-auto';
  @Input() borderRadius = 'top-border-radius';
  @Input() fontSize = 'text-sm';
  countdownDate = input<string>();
  countdownIsOver = signal(false);
  showCountDown = computed(
    () => new Date(this.countdownDate()).getTime() - new Date().getTime() > 0 && !this.countdownIsOver()
  );

  get customMargin() {
    return this.margin;
  }

  get elementWidth() {
    return this.width;
  }

  get shortText() {
    return this.text?.replace(/<\/?[^>]+(>|$)/g, '');
  }

  get backgroundColor() {
    return 'background-gradient';
  }

  get stickerPosition() {
    return `position-${this.position?.toLowerCase()}`;
  }

  get size() {
    if (this.dynamicSize) {
      return 'dynamic';
    }
    return '';
  }
}
