<div
  class="flex transition-all"
  [class.opacity-0]="hide()"
  [class.-translate-y-2]="hide()"
  [ngClass]="{
    'countdown-for-offer-light': isOfferBoxLight(),
    'justify-center': !isOfferBoxLight(),
    'pb-6': showTexts(),
  }"
>
  <div [ngClass]="{ 'mx-1': !isOfferBoxLight() && showTexts(), 'mr-1': isOfferBoxLight() }">
    <h3 [class]="titleCss()">{{ days }}</h3>
    @if (showTexts()) {
      <p [class]="textCss()">{{ ['days'] | translate }}</p>
    }
  </div>
  <div [ngClass]="{ 'mx-1': showTexts() }">
    <h3 [class]="titleCss()">:</h3>
  </div>
  <div [ngClass]="{ 'mx-1': showTexts() }">
    <h3 [class]="titleCss()">{{ hours }}</h3>
    @if (showTexts()) {
      <p [class]="textCss()">{{ ['hours'] | translate }}</p>
    }
  </div>
  <div [ngClass]="{ 'mx-1': showTexts() }">
    <h3 [class]="titleCss()">:</h3>
  </div>
  <div [ngClass]="{ 'mx-1': showTexts() }">
    <h3 [class]="titleCss()">{{ minutes }}</h3>
    @if (showTexts()) {
      <p [class]="textCss()">{{ ['minutes'] | translate }}</p>
    }
  </div>
  <div [ngClass]="{ 'mx-1': showTexts() }">
    <h3 [class]="titleCss()">:</h3>
  </div>
  <div [ngClass]="{ 'mx-1': showTexts() }">
    <h3 [class]="titleCss()">{{ seconds }}</h3>
    @if (showTexts()) {
      <p [class]="textCss()">{{ ['seconds'] | translate }}</p>
    }
  </div>
</div>
