<div
  class="flex justify-center items-center h-full"
  [ngClass]="[backgroundColor, stickerPosition, size, elementWidth, customMargin, borderRadius]"
>
  @if (showCountDown()) {
    <lib-countdown
      (countdownIsOver)="this.countdownIsOver.set(true)"
      titleCss="text-3xl font-bold text-white"
      [showTexts]="false"
      [countdownDateFromPrismic]="countdownDate()"
    >
    </lib-countdown>
  } @else {
    <p class="text-center px-1 py-[0.10rem]" [ngClass]="[fontSize]">
      <span class="text-white font-bold">
        {{ shortText }}
      </span>
    </p>
  }
</div>
